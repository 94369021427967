import React from 'react'
import Banner from '../components/Banner';
import Layout from '../layout/Layout';
import MainQuote from '../components/Quote';
import Seo from '../layout/Seo';

const design = ({ pageContext }) => {

    const { locale } = pageContext;
    function Child({locale}){
return(<>
 <Seo 
                title="Empresa de Diseño en Bogotá, Decoración en Bogotá" 
                description="Diseño e implementamos fachadas, decoración y pintura."
                pathname="https:localhost"
            />
            <Banner title={locale.design.banner.title} subtitle={locale.design.banner.subtitle} />
            <MainQuote title={locale.design.mainQuote} locale={locale} />
            </>)
    }
    return (
        <Layout locale={locale} background="bg-image-design">
            {props => <Child {...props} />}
        </Layout>
    )
}

export default design
